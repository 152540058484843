import { Article, ArticleTags, SCAPIAnnotations, SCAPIArticle } from '../types'
import typesConfig from '../assets/articleTypesConfig'
import colours from '../utils/colours'
import {
    BRAND_ARTICLE_TYPES,
    EXECLUDED_BRANDS_LIST,
    GENRE_ARTICLE_TYPES,
    POWERED_BY_BRANDS_LIST,
    POWERED_BY_OPTIONS_REVERSE,
    CM_GENRE_LIST,
} from '../assets/constants'
import { slugify } from '../components/utils/helpers'

export function getGenreAndBrand(article: Article) {
    const genre = article.tags?.genre?.label || ''

    const brand = article.tags?.brand?.label || ''

    return [genre, brand]
}

export function getArticleType(article: Article) {
    const [genre, brand] = getGenreAndBrand(article)

    let type = (GENRE_ARTICLE_TYPES[
        genre as keyof typeof GENRE_ARTICLE_TYPES
    ] ||
        BRAND_ARTICLE_TYPES[
            brand as keyof typeof BRAND_ARTICLE_TYPES
        ]) as string

    if (genre === 'Opinion' && type == null) {
        type = 'generalOpinion'
    }

    if (article.isPartnerContent) {
        type = 'partnerContent'
    }

    return type || ''
}

export function getColour(articleType: string) {
    const colourString = typesConfig[articleType as keyof typeof typesConfig]
        ? typesConfig[articleType as keyof typeof typesConfig].colour
        : null
    const colour = colours.find(colour => {
        return colour.name === colourString
    })

    return colour ? colour.hex : null
}

export function parseArticleTags(articleTags: ArticleTags) {
    const tags = Object.entries(articleTags).reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (acc: [string, any][], [, value]) => {
            if (!value) {
                return acc
            }

            if (Array.isArray(value)) {
                const tags = value.map(tag => tag.label)
                return [...tags, ...acc]
            }

            if (!value.label) {
                return acc
            }

            return [value.label, ...acc]
        },
        []
    )

    return tags
}

export const mapTagsFromAnnotations = (annotations?: SCAPIAnnotations) => {
    const annotationList = [
        ...(annotations?.genres || []),
        ...(annotations?.abouts || []),
        ...(annotations?.displayTags || []),
    ]

    const genre =
        CM_GENRE_LIST.find(genre =>
            annotationList.find(tag => tag.id === genre.id)
        ) || null

    const brand =
        annotations?.brands?.filter(
            brand =>
                !EXECLUDED_BRANDS_LIST.find(option => option.id === brand.id)
        )[0] || null

    const nominated =
        annotations?.displayTags?.[0] || annotations?.genres?.[0] || null

    return {
        genre: genre
            ? {
                  id: genre.id,
                  label: genre.name,
                  name: genre.name,
                  slug: slugify(genre.name),
                  path: genre.name,
              }
            : null,
        brand: brand
            ? {
                  id: brand.id,
                  label: brand.name,
                  name: brand.name,
                  slug: brand.slug || '',
                  path: brand.name,
              }
            : null,
        nominated: nominated
            ? {
                  id: nominated.id,
                  label: nominated.name,
                  name: nominated.name,
                  slug: nominated.slug || '',
                  path: nominated.name,
              }
            : null,
        ...annotations?.abouts?.reduce((acc, tag) => {
            if (!tag?.name) return acc

            return {
                ...acc,
                [tag.name]: {
                    id: tag.id,
                    label: tag.name,
                    name: tag.name,
                    slug: tag.slug || '',
                },
            }
        }, {}),
    }
}

const mapPoweredByFromBrands = (brands: SCAPIAnnotations['brands']) => {
    return brands.find(brand =>
        POWERED_BY_BRANDS_LIST.find(option => option.id === brand.id)
    )
}

const mapSpecialReportData = (contentPackages?: [SCAPIArticle]) => {
    const specialReport = contentPackages?.[0]
    if (specialReport) {
        return {
            uuid: specialReport.id,
            headLine: specialReport.title,
            url: specialReport.url,
        }
    }
    return null
}

export const filterLatestArticlesToRemoveTopPageArticles = (
    articles: SCAPIArticle[],
    topPageArticles: SCAPIArticle[]
) => {
    if (!topPageArticles) {
        return articles
    }
    const filteredArticles = articles.filter(
        article =>
            !topPageArticles.some(
                topPageArticle => topPageArticle.id === article.id
            )
    )
    return filteredArticles
}

export const mapSCAPItoFDIArticle = (article: SCAPIArticle) => {
    const poweredBy = mapPoweredByFromBrands(article?.annotations?.brands || [])
    setByLineAuthors(article)
    return {
        __typename: 'Article',
        // Basic fields
        id: article.id,
        contentID: article.contentID,
        title: article.title,
        published_date: article.publishedDate,
        first_published_date: article.firstPublishedDate,
        unpublish_date: null,
        body_text: article.body,
        teaser: article.lede || '',
        shorthandEmbed: article.shorthandEmbed || '',
        specialReport: mapSpecialReportData(article.containedInPackages),
        introduction: article.introduction || '',
        // Video handling
        videoid: article.video?.videoId || null,

        // Source and type
        powered_by: poweredBy
            ? POWERED_BY_OPTIONS_REVERSE[
                  poweredBy.name as keyof typeof POWERED_BY_OPTIONS_REVERSE
              ]
            : 'NONE',

        // Image handling - maintaining old structure
        image: article.teaserImage
            ? {
                  id: '', // Would need mapping if available
                  name: article.title,
                  altTag: article.title,
                  caption: article.captionImage || '',
                  image: article.teaserImage,
                  isVideoTeaser: article.video ? true : false,
                  remoteId: null,
                  cacheId: null,
                  tags: null,
              }
            : undefined,

        // Tags handling - converting new tag structure to old
        tags: mapTagsFromAnnotations(article?.annotations),

        // SEO fields
        seo_page_title: article.seoMetaTitle || '',
        seo_meta_keywords: article.seoMetaKeywords || '',
        seo_meta_description: article.seoMetaDescription || null,

        // Additional fields
        hide_header_image: false,
        teaser_image_caption: article.captionImageTitle || '',
        advertorial_sponsor_disclaimer: '',
        acast_podcast_id: article.podcast || '',
        cacheId: article.id,
        url: article.url || `/content/${article.id}`,
        partner_content:
            article.isPartnerContent && article.sponsor
                ? [
                      {
                          id: article.sponsor?.id,
                          name: article.sponsor?.name,
                          link: article.sponsor?.url,
                          image: article.sponsor?.imageUrl,
                      },
                  ]
                : null,
        byline: article.byline || '',
        annotations: article?.annotations,
        isPartnerContent: article?.isPartnerContent,
        sponsor: article?.sponsor,
    }
}

export const setByLineAuthors = (article: SCAPIArticle) => {
    const authors = article?.annotations?.authors || []
    const byline = article.byline || ''

    if (byline && !authors.length) {
        return byline
    } else if (!byline || !authors.length) {
        return ''
    }

    const bylineAuthors = byline.split(',').map(author => author.trim())

    // Create HTML links for each matching author
    const linkedByline = bylineAuthors.map(bylineAuthor => {
        const matchingAuthor = authors.find(
            author =>
                author?.name &&
                bylineAuthor.toLowerCase().includes(author.name.toLowerCase())
        )

        if (matchingAuthor?.name && matchingAuthor?.slug) {
            return `<a class="article-metadata__author" href="/${matchingAuthor.slug}" rel="author">${matchingAuthor.name}</a>`
        }
        return bylineAuthor
    })

    // Join the linked authors back together with commas
    article.byline = linkedByline.join(', ')
}
